import _ from "lodash";
import Header from "./components/Header";
import Report from "./components/Report";
import {useDispatch} from "react-redux";
import getReportData from "./actions/actions";
import {useEffect, useRef} from "react";
import {useForm, useLocalStore} from "@dladio/hooks";
import {Box, DefaultCommandBar, TitleBar} from "@dladio/core-ui";
import {dispatchAction, useAppBridgeSelector} from "@dladio/app-bridge";

const Home = () => {
    const dispatch = useDispatch()
    const {model} = useAppBridgeSelector()
    const isInitialLoading = useRef(true)
    const {model: data, setStore} = useLocalStore('report')
    const {form, setForm} = useForm("date-filter")

    useEffect(() => {
        executeGetData()
    }, [form])

    useEffect(() => {
        if (isInitialLoading.current && data) {
            setForm(
                {
                    from: _.get(data, 'from'),
                    to: _.get(data, 'to')
                }
            )
            isInitialLoading.current = false
        }

    }, [data])

    const executeGetData = () => {
        dispatch(getReportData(form, _.get(model, 'params.id')) as any).then((reportData: any) => {
                let clonedArray = _.cloneDeep(reportData)
                clonedArray.report = sortData(reportData?.report)
                setStore(clonedArray)
            }
        ).catch((error: any) => {
            setStore({})
        })
    }

    const sortData = (array: any) => {
        let newLinesArray: any = []
        for (let item of array) {
            let sortedLinesArray: any = _.cloneDeep(item)?.lines?.sort((prev: any, curr: any) => {
                if (prev.description.includes("head#")) return 0
                else return prev.description.localeCompare(curr.description);
            });

            newLinesArray.push({title: item?.title, lines: sortedLinesArray})
        }
        return newLinesArray
    }

    let _reports = _.isEmpty(_.get(data, "report")) ? [] : _.get(data, "report")

    const reportContainer: any = _reports?.map((report: any, index: any) => {
        return (
            <Report key={index} data={report}/>
        )
    })

    return (
        <>
            <TitleBar
                title={data?.title}
                subTitle={'P&L Report'}
            />
            <DefaultCommandBar
                actions={[
                    {
                        key: 1,
                        text: "Back",
                        iconProps: {iconName: "Back"},
                        onClick: () => {
                            dispatchAction(
                                {
                                    action: "navigate",
                                    payload: -1,
                                },
                                "https://helios.dlad.io/"
                            );
                        },
                    }
                ]}
            />

            <Box>
                <Header data={data}/>
            </Box>

            {reportContainer}
        </>

    );
};

export default Home;
