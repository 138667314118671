import {useRoutes} from "react-router-dom";
import Home from "../components/pages/Home/Home";


const RouterConfigs = () => {


    const element = useRoutes(
        [
            {
                path: "/",
                element:  <Home/>,
            }
        ]);

    return element;
};

export default RouterConfigs;