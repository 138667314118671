import _ from "lodash";
import moment from "moment/moment";
import {erpMethodApi} from "@dladio/service";
import {EXEC_QUERY_METHOD} from "@dladio/service/dist/const/erp-method.consts";

const getReportData =  (form: any,query:any) => {
    return async (dispatch: any) => {
            let {data,error}: any = await dispatch(erpMethodApi.endpoints.execMethodApi.initiate(
                {
                    method: EXEC_QUERY_METHOD,
                    body: {
                        name: query,
                        from: _.get(form, 'from') ? moment(_.get(form, 'from')).format("YYYY-MM-DD") : moment(new Date()).format("YYYY-MM-DD"),
                        to: _.get(form, 'to') ? moment(_.get(form, 'to')).format("YYYY-MM-DD") : moment(new Date()).format("YYYY-MM-DD")
                    }
                }
            ))
            if (!error) return _.get(data, 'message')
            else throw error
    }
}

export default getReportData