import _ from "lodash";
import {Text} from "@fluentui/react";
import {Box, Stack} from "@dladio/core-ui";

interface ReportInterface {
    data: any
}

const Report = ({data}: ReportInterface) => {
    const lineMarkup = (
        <Box>
            <div className={"text-left pt-2 flex flex-row justify-between"}>
                <h1 className={"text-md font-bold"}>{data?.title}</h1>
            </div>
            <div className="pl-4 pt-1 flex flex-col">
                {
                    _.isArray(data?.lines) ? data?.lines.map((line: any, index: any) =>
                        <Line text={line?.description} value={line?.value} key={index}/>
                    ) : []
                }
            </div>
        </Box>
    )

    return (
        <>
            {
                data?.lines ? (lineMarkup) : <TitleWithValue title={data?.title} value={data?.value}/>
            }

        </>
    )
}
export default Report
const TitleWithValue = ({title, value}: any) => {
    let style = ""
    let total = false

    if (title.split("#")[0] === "head" || title.split("#")[1] === "head") {
        style = "font-bold"

        if (title.split("#")[0] === "total" || title.split("#")[1] === "total") {
            total = true
        }

        title = title.split("#")[title.split("#")?.length - 1]
    }

    return (
        <>
            <Box>
                <div className={" py-2 flex flex-row justify-between hover:cursor-pointer px-2 hover:rounded-md hover:bg-blue-100"}>
                    <h1 className={"text-md text-left font-bold flex items-center"}>{title}</h1>
                    <div className={`text-right ${style}`}>
                        {
                            total ? <>
                                    <hr className={"w-full h-px my-1 bg-black border-0"}></hr>
                                    <Text className={style}>{value}</Text>
                                    <hr className={"w-full h-px my-1 bg-black border-0"}></hr>
                                    <hr className={"w-full h-px my-1 border-dotted bg-black border-0"}></hr>
                                </> :
                                <Text className={style}>{value}</Text>
                        }
                    </div>
                </div>
            </Box>
        </>
    )
}
const Line = ({text, value}: any) => {
    let style = ""
    let total = false

    if (text.split("#")[0] === "head" || text.split("#")[1] === "head") {

        if (text.split("#")[0] === "total" || text.split("#")[1] === "total") {
            total = true
        }

        style = "font-bold"
        text = text.split("#")[text.split("#")?.length - 1]
    }

    return (
        <>
            <Stack horizontal className={`flex justify-between hover:cursor-pointer px-2 hover:rounded-md hover:bg-blue-100`}>
                <div className={`mr-3 flex items-center `}>
                    <p className={`flex items-center  ${style}`}>{text}</p>
                </div>
                <div className={''}>
                    {
                        total ? <>
                                <hr className={"w-full h-px my-1 bg-black border-0"}></hr>
                                <p className={style}>{value}</p>
                                <hr className={"w-full h-px my-1 bg-black border-0"}></hr>
                                <hr className={"w-full h-px my-1 bg-black border-0 border-dotted"}></hr>
                            </> :
                            <p className={style}>{value}</p>
                    }
                </div>
            </Stack>
        </>
    )
}