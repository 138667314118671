import _ from "lodash";
import React from "react";
import {useForm} from "@dladio/hooks";
import {DatePicker, Stack} from "@fluentui/react";

const Header = ({data}: any) => {

    const {form, setForm} = useForm("date-filter")

    function fromHandler(value: any) {
        setForm('from', value)
    }

    function toHandler(value: any) {
        setForm('to', value)
    }

    function clearFilter() {
        setForm('from', new Date())
    }

    return (
        <div className={'mx-2 mb-2 items-center flex flex-col justify-center'}>
            <Stack horizontal className={' rounded-2 gap-2 p-2 '}>
                <DatePicker
                    label={'From'}
                    allowTextInput
                    value={_.get(form, 'from') ? new Date(_.get(form, 'from')) : new Date}
                    onSelectDate={fromHandler as (date: Date | null | undefined) => void}
                />
                <DatePicker
                    label={'To'}
                    allowTextInput
                    ariaLabel="Select a date"
                    value={_.get(form, 'to') ? new Date(_.get(form, 'to')): new Date}
                    onSelectDate={toHandler as (date: Date | null | undefined) => void}
                />
            </Stack>
        </div>
    )
}

export default Header