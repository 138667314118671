import {CoreUIProvider} from "@dladio/core-ui";
import React from "react";
import RouterConfigs from "./route/router.config";
import {BrowserRouter} from "react-router-dom";
import {AppBridgeProvider} from "@dladio/app-bridge";

function App() {
    return (
        <AppBridgeProvider apiKey={"PNL-Report"}>
            <CoreUIProvider>
                <BrowserRouter>
                    <>
                        <RouterConfigs/>
                    </>
                </BrowserRouter>
            </CoreUIProvider>
        </AppBridgeProvider>
    );
}

export default App;
